import { clearFieldsDependentAbona, receiveFecha, receiveFechaFormat2 } from "../../../../../adapters/helpers"

const FacturasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idfactura || '',
                    numerofactura: item.numerofactura ? item.numerofactura : item.numeroproforma,
                    numeroproforma: item.numeroproforma || '',
                    abona: item.abona ? (item.abona === 'E' ? (item.entidad?.nombre || item.entidad?.nombrecomercial) : item.persona?.nombre_completo) : '',
                    abonaKey: item.abona || '',
                    fechafactura: receiveFechaFormat2(receiveFecha(item.fechafactura)),
                    estado: item.estado || '',
                    importe: item.importe || '',
                    importe__label: item.importe ? (Number(item.importe)?.toFixed(2) + ' €') : '',
                    importe_base: item.importe_base || '',
                    importe_iva: item.importe_iva || '',
                    importe_irpf: item.importe_irpf || '',

                    idservicio: item.servicio?.idservicio || '',
                    servicio: item.servicio?.servicio || '',

                    identidad: item.entidad?.identidad || '',
                    entidad: item.entidad?.nombrecomercial || '',

                    identidadsedecliente: item.identidadsedecliente || '',
                    entidadsede: item.entidad_sede?.nombresede || '',

                    idpersona: item.persona?.idpersona || '',
                    persona: `${item.persona?.nombre || ''} ${item.persona?.apellido1 || ''} ${item.persona?.apellido2 || ''} ` || '',
                    identidadpropia: item.entidad_propia?.identidad || '',

                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                

                const items = dataTemp?.data?.map((item: any) => (this.schema(item)))

                return {
                    data: {
                        total: dataTemp?.total,
                        per_page: dataTemp?.per_page,
                        current_page: dataTemp?.current_page,
                        items
                    },
                    status: data?.status
                }
            }
        },
    },

    GET_EXCEL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
    
    POST_EXPORTAR_ASIENTOS_CONTABLES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                
                

                const items = this.schema(dataTemp)


                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}


                const data = {
                    filtros: item.filtros || {},
                    lista_ids: item.lista_ids || [],
                    lista_exclusion: item.lista_exclusion || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_PDFS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    filtros: item.filtros || '',
                    lista_ids: item.lista_ids || '',
                    lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_NOTIFICAR: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    filtros: item.filtros || {},
                    lista_ids: item.lista_ids || [],
                    lista_exclusion: item.lista_exclusion || [],
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },
}

const FacturaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: item.idfactura || '',

                    abona: item.abona || '',
                    archivo: item.archivo || '',
                    cobros: item.cobros || [],

                    identidad: item.entidad?.identidad || '',
                    entidad: item.entidad?.nombre || item.entidad?.nombrecomercial || '',

                    identidadsedecliente: item.identidadsedecliente || '',
                    entidadsede: item.entidad_sede?.nombresede || '',

                    facturadigital: item.facturadigital || false,
                    facturas_lineas: item.facturas_lineas || [],

                    fechacontabilidad: receiveFecha(item.fechacontabilidad),
                    fechafactura: receiveFecha(item.fechafactura),

                    motivorectificacion: item.motivorectificacion || '',

                    numerofactura: item.numerofactura || '',
                    numeroproforma: item.numeroproforma || '',

                    idpersona: item.persona?.idpersona || '',
                    persona: `${item.persona?.nombre || ''} ${item.persona?.apellido1 || ''} ${item.persona?.apellido2 || ''} ` || '',

                    idservicio: item.servicio?.idservicio || '',
                    servicio: item.servicio?.servicio || '',

                    estado: item.estado || '',

                    factura_rectificada: item.factura_rectificada || '',
                    factura_rectificativa: item.factura_rectificativa || '',

                    identidadpropia: item.entidad_propia?.identidad || '',
                    entidadpropia: item.entidad_propia?.entidad?.nombre || '',
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data?.data

                const items = this.schema(dataTemp)

                return {
                    data: {
                        ...items
                    },
                    status: data?.status
                }
            }
        },
    },

    POST: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    data: item.data,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idservicio: item.idservicio || '',
                    fechafactura: item.fechafactura || '',
                    abona: item.abona || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    idfacturarectificada: item.idfacturarectificada || '',
                    motivorectificacion: item.motivorectificacion || '',
                    facturadigital: item.facturadigital || false,
                    identidadsedecliente: item.identidadsedecliente || '',
                    observaciones: item.observaciones || '',
                    tipo: item.tipo || '',
                    factura_lineas: item.factura_lineas || '',
                    cobro: item.cobro || '',

                    identidadpropia: item.identidadpropia || '',
                }

                const dataTemp = clearFieldsDependentAbona(data)

                return dataTemp
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    PATCH: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    idservicio: item.idservicio || '',
                    fechafactura: item.fechafactura || '',
                    abona: item.abona || '',
                    idpersona: item.idpersona || '',
                    identidad: item.identidad || '',
                    idfacturarectificada: item.idfacturarectificada || '',
                    motivorectificacion: item.motivorectificacion || '',
                    facturadigital: item.facturadigital || false,
                    identidadsedecliente: item.identidadsedecliente || '',
                    observaciones: item.observaciones || '',
                    // tipo: item.tipo || '',
                    // factura_lineas: item.factura_lineas || '',
                    // cobro: item.cobro || ''

                    // Se puede actualizar el identidadpropia?
                    // identidadpropia: item.identidadpropia || '',
                }

                const dataTemp = clearFieldsDependentAbona(data)

                return dataTemp

            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    POST_ABONAR: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    fechafactura: item.fechafactura || '',
                    motivorectificacion: item.motivorectificacion || '',
                    cobro: item.cobro || '',
                    idcondicionpago: item.idcondicionpago || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    GET_PDF: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: item.data,
                    headers: item.headers
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },

        send: {

            schema: function (item: any) {

                if (!item) item = {}
                

                const data = {
                    // filtros: item.filtros || '',
                    // lista_ids: item.lista_ids || '',
                    // lista_exclusion: item.lista_exclusion || '',
                }

                return data
            },

            adapter: function (data: any) {

                const items = this.schema(data)

                return {
                    ...items
                }
            }
        },
    },

    
    DELETE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    message: item.message,
                    status: item.status
                }

                return data
            },

            adapter: function (data: any) {

                const dataTemp = data?.data

                const items = this.schema(dataTemp)

                
                return {
                    data: items,
                    status: data?.status
                }
            }
        },
    },

}


export {
    FacturasAdapters,
    FacturaAdapters
}