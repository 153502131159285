import React, { useState } from 'react'
import '@assetsTheme/css/layouts/LayoutMain1.scss'

import Navleft from './Navleft';
import Body from './Body';

interface ILayoutMain {
    children: any;
    label?: any;
}

const LayoutMain = ({ children, label }: ILayoutMain) => {

    const [showNavLeft, setshowNavLeft] = useState(false)

    return (
        <section className='LayoutMain1'>
            {
                children?.map((childElement: any, indexchildElement: any) =>
                    addPropsToReactElement(childElement, { showNavLeft, setshowNavLeft, label, 'key': indexchildElement } )
                )
            }
        </section>
    )
}

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}


LayoutMain.NavLinks = Navleft
LayoutMain.Body = Body

export default LayoutMain


