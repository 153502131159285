import { useAxios } from "../../../../../../hooks/useAxios"
import { OfertaAdapters, OfertasAdapters } from "./ofertas.adapter"


const OfertasServices = {

    GET: function () {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/ofertasempleo`, type: 'json', adapters: OfertasAdapters.GET })
    },

    GET_EXCEL: function () {

        return useAxios({ method: 'get', url: `/visados/admin/visados`, type: 'blob', adapters: OfertasAdapters.GET_EXCEL })
    },

    GET_BETWEEN_DATES: function () {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/fechas-con-eventos/listar`, type: 'json', adapters: OfertasAdapters.GET_BETWEEN_DATES })
    },

    GET_CHECK_DISPONIBILIDAD: function (idevento: any) {

        return useAxios({ method: 'get', url: `/inscripciones/eventos/${idevento}/check-disponibilidad`, type: 'json', adapters: OfertasAdapters.GET_CHECK_DISPONIBILIDAD })
    },
}

const OfertaServices = {

    GET: function (id: string) {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/ofertasempleo/${id}`, type: 'json', adapters: OfertaAdapters.GET })
    },

    
    GET_BUSCAR_PERSONAS: function (id: string) {

        return useAxios({ method: 'get', url: `/bolsadetrabajo/admin/ofertasempleo/${id}/buscar`, type: 'json', adapters: OfertaAdapters.GET_BUSCAR_PERSONAS })
    },

    POST: function () {

        return useAxios({ method: 'post', url: `/bolsadetrabajo/admin/ofertasempleo`, type: 'json', adapters: OfertaAdapters.POST })
    },

    PATCH: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${idevento}`, type: 'json', adapters: OfertaAdapters.PATCH })
    },

    PATCH_CHANGE_ESTADO: function (idevento: string) {

        return useAxios({ method: 'patch', url: `/inscripciones-bolsa/eventos/${idevento}`, type: 'json', adapters: OfertaAdapters.PATCH_CHANGE_ESTADO })
    },


    DELETE: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones-bolsa/eventos/${idvisado}`, type: 'json', adapters: OfertaAdapters.DELETE })
    },

    PATCH_BAJA_OFERTA: function (idoferta: any) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${idoferta}/darBajaOferta`, type: 'json', adapters: OfertaAdapters.PATCH })
    },

    PATCH_IMAGEN: function (idevento: string) {

        return useAxios({ method: 'post', url: `/inscripciones-bolsa/eventos/${idevento}/imagenlistado/subir?_method=PATCH`, type: 'json', adapters: OfertaAdapters.PATCH_IMAGEN })
    },

    DELETE_IMAGEN: function (idvisado: any) {

        return useAxios({ method: 'delete', url: `/inscripciones-bolsa/eventos/${idvisado}/imagenlistado/eliminar`, type: 'json', adapters: OfertaAdapters.DELETE_IMAGEN })
    },

    PATCH_NIVEL_IDIOMA: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${id}/idiomas`, type: 'json', adapters: OfertaAdapters.PATCH_NIVEL_IDIOMA })
    },

    PATCH_TIPO_ESTUDIO: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${id}/tiposestudios`, type: 'json', adapters: OfertaAdapters.PATCH_TIPO_ESTUDIO })
    },

    PATCH_FUNCION: function (id: string) {

        return useAxios({ method: 'patch', url: `/bolsadetrabajo/admin/ofertasempleo/${id}/funciones`, type: 'json', adapters: OfertaAdapters.PATCH_FUNCION })
    },

    POST_SUBIR_ADJUNTO: function (id: string) {

        return useAxios({ method: 'POST', url: `/bolsadetrabajo/admin/ofertasempleo/${id}/adjunto`, type: 'json', adapters: OfertaAdapters.POST_SUBIR_ADJUNTO })
    },

    DELETE_ADJUNTO: function () {

        return useAxios({ method: 'DELETE', url: `/bolsadetrabajo/admin/ofertasempleo/adjunto`, type: 'json', adapters: OfertaAdapters.DELETE_ADJUNTO })
    },
}

export {
    OfertasServices,
    OfertaServices
}