import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import logo from '@assetsTheme/images/icon.png'
import logo2 from '@assetsTheme/images/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../../store/slices/auth';
import { DropdownUser } from '../DropdownUser';
import { useState } from 'react';
import { ModalCambioContrasenia } from '../ModalCambioContrasenia';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';

interface IBody {
    children: React.ReactNode;
    setshowNavLeft?: (boolean: boolean) => boolean,
    showNavLeft?: boolean,
    label?: string
}

const Body = ({ children, setshowNavLeft = (() => false), showNavLeft, label = '' }: IBody) => {

    const dispatch = useDispatch()



    // Modal Cambiar credenciales

    const [showCambiarPassword, setShowCambiarPassword] = useState({
        value: false,
        payload: null
    });

    const handleCloseCambiarPassword = () => setShowCambiarPassword({
        value: false,
        payload: null
    })

    const handleShowCambiarPassword = (payload: any) => setShowCambiarPassword({
        value: true,
        payload
    });

    const { auth } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate()

    const handleClickNavigateTo = (url: string) => navigate(`/${url}`)

    return (
        <section className="LayoutMain2__contentright container pb-3">
            <nav className='LayoutMain2__navtop d-flex justify-content-between align-items-center'>
                <span className='d-none d-md-inline-block fw-normal '>
                    <img src={logo2} alt="" width={100} />
                </span>
                <div className="d-flex align-items-center d-md-none pointer" onClick={() => setshowNavLeft(!showNavLeft)}>

                    <img src={logo} alt="" height='35px' className='ms-1' />
                </div>

                <div className="d-flex align-items-center" id=''>

                    <DropdownUser
                        auth={auth}
                        dispatch={dispatch}
                        logOut={logOut}
                        handleShowCambiarPassword={handleShowCambiarPassword}
                    />

                    
                </div>
            </nav>
            
            <div className='mt-3'>
                <Button onClick={() => handleClickNavigateTo('')} variant='outline-primary' size='sm'>
                    <FontAwesomeIcon icon={faArrowLeft} /> Menu principal
                </Button>
            </div>

            <main className='LayoutMain2__contentmain'>
                <h5 className="h5 text-center my-0 py-3">{label}</h5>
                {children}
            </main>

            <ModalCambioContrasenia
                showCambiarPassword={showCambiarPassword}
                handleCloseCambiarPassword={handleCloseCambiarPassword}
            />
        </section>
    )
}

export default Body